import { css, StyleDeclaration } from 'aphrodite';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useErrorMessages } from '../../../models/hooks/appStateHooks';
import { useEventLogging } from '../../../models/Logging';
import { baseStyleSheet } from '../../styles/styles';
import { LoadingSpinner } from '../LoadingSpinner';
import { TextInput } from '../TextInput';
import { TinyPopover } from '../TinyPopover';
import { styleSheet } from './styles';

interface ITimezoneOption {
	dataContext: ITimezoneData;
	text: string;
}

interface IProps {
	disabled?: boolean;
	numOptionsToShow?: number;
	onTimezoneSelected: (tz: ITimezoneData) => void;
	placeholder?: string;
	initialTimezoneValue?: string;
	styles?: StyleDeclaration[];
	inputStyles?: StyleDeclaration[];
}

export interface ITimezoneData {
	abbreviation: string;
	alternativeName: string;
	continentCode: string;
	continentName: string;
	countryCode: string;
	countryName: string;
	group: string[];
	mainCities: string[];
	name: string;
	rawFormat: string;
	rawOffsetInMinutes: number;
	shortName: string;
}

// [0] = keyword to assign which will be searched for in the user's input
// [1] = the name of the timezone to be assigned.
const keywordOptionsMap: [string, string][] = [
	['Eastern Standard Time', 'America/New_York'],
	['Central Standard Time', 'America/Chicago'],
	['Mountain Standard Time', 'America/Denver'],
	['Pacific Standard Time', 'America/Los_Angeles'],
];

const getTimezoneData = (rawTimeZones: ITimezoneData[]): ITimezoneData[] => {
	const tzsByContinent: Record<string, ITimezoneData[]> = {};

	rawTimeZones.map((tz: ITimezoneData) => {
		if (tz.continentCode in tzsByContinent) {
			tzsByContinent[tz.continentCode].push(tz);
		} else {
			tzsByContinent[tz.continentCode] = [tz];
		}
	});

	let tzs = sortTimezones(tzsByContinent.NA);
	Object.keys(tzsByContinent).map((key: string) => {
		if (key !== 'NA') {
			tzs = [...tzs, ...sortTimezones(tzsByContinent[key])];
		}
	});

	return tzs;
};

const sortTimezones = (tzs: ITimezoneData[] = []) => {
	return tzs.sort((a: ITimezoneData, b: ITimezoneData) => {
		const A = a.name.toUpperCase();
		const B = b.name.toUpperCase();
		if (A < B) {
			return -1;
		}
		if (B < A) {
			return 1;
		}
		return 0;
	});
};

export const TimezonePicker: React.FC<IProps> = ({
	disabled = false,
	numOptionsToShow = 5,
	onTimezoneSelected,
	placeholder,
	initialTimezoneValue = '',
	styles = [],
	inputStyles = [],
}) => {
	const errorMessages = useErrorMessages();
	const logger = useEventLogging();
	const [value, setValue] = useState(initialTimezoneValue);
	const searchTimeout = useRef<NodeJS.Timeout>(null);
	const executing = useRef(false);
	const [cities, setCities] = useState<ITimezoneOption[]>([]);
	const [timezones, setTimezones] = useState<ITimezoneOption[]>([]);
	const [isOpen, setIsOpen] = useState(false);
	const [highlighted, setHighlighted] = useState(-1);
	const [loadingTimezoneData, setLoadingTimezoneData] = useState(false);
	const [timezoneData, setTimezoneData] = useState<ITimezoneData[]>([]);

	useEffect(() => {
		setLoadingTimezoneData(true);
		fetch('./assets/raw-time-zones.json')
			.then(res => res.json())
			.then(res => getTimezoneData(res))
			.then(res => setTimezoneData(res))
			.then(() => setLoadingTimezoneData(false))
			.catch(err => {
				setLoadingTimezoneData(false);
				logger.logApiError('LoadRawTimeZoneData-Error', err);

				errorMessages.pushApiError(err);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		clearTimeout(searchTimeout.current);

		searchTimeout.current = setTimeout(() => {
			if (!executing.current) {
				executing.current = true;
				search();
				executing.current = false;
			}
		}, 200);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	useEffect(() => {
		if (!isOpen) {
			setHighlighted(-1);
		}
	}, [isOpen]);

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.value) {
			setIsOpen(false);
		}
		if (e.target.value) {
			setIsOpen(true);
		}
		setHighlighted(-1);
		setValue(e.target.value);
	};

	const onInputFocus = () => {
		if (cities.length > 0 || timezones.length > 0) {
			setIsOpen(true);
		}
	};

	const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (isOpen) {
			if (event.key === 'Enter') {
				if (highlighted > -1) {
					if (highlighted < cities.length) {
						selectTimezone(cities[highlighted])();
					} else if (highlighted < cities.length + timezones.length) {
						selectTimezone(timezones[highlighted - cities.length])();
					}
				}
			} else if (event.key === 'ArrowDown') {
				setHighlighted(highlighted + 1);
			} else if (event.key === 'ArrowUp') {
				setHighlighted(highlighted > 0 ? highlighted - 1 : 0);
			}
		}
	};

	const onRequestClose = () => {
		setIsOpen(false);
	};

	const renderTimezoneOptions = () => {
		if (!value) {
			return null;
		}

		if (cities.length === 0 && timezones.length === 0) {
			return <div className={css(styleSheet.noResults)}>No results found</div>;
		}

		const max = cities.length + timezones.length - 1;

		if (highlighted > max) {
			setHighlighted(max);
		}

		let results: JSX.Element[] = [];

		if (timezones.length) {
			results = renderTimezoneResults('Timezones', timezones, highlighted - cities.length);
		}

		if (cities.length) {
			results = [...results, ...renderTimezoneResults('Cities', cities, highlighted)];
		}

		return results;
	};

	const renderTimezoneResults = (header: string, opts: ITimezoneOption[], highlightIndex: number) => {
		const results = [
			<div key={uuid()} className={css(styleSheet.header)}>
				{header}
			</div>,
		];

		for (let i = 0; i < opts.length; i++) {
			if (i >= numOptionsToShow) {
				break;
			}

			results.push(
				<div
					className={css(styleSheet.option, i === highlightIndex && styleSheet.highlighted)}
					key={uuid()}
					onClick={selectTimezone(opts[i])}
				>
					{opts[i].text}
				</div>
			);
		}

		return results;
	};

	const search = () => {
		const newCities: ITimezoneOption[] = [];
		const newTimezones: ITimezoneOption[] = [];

		if (!value) {
			setIsOpen(false);
			setCities(newCities);
			setTimezones(newTimezones);
			return;
		}

		const v = value.toLowerCase();

		timezoneData.map(t => {
			const tzName = t.name.toLowerCase();

			t.mainCities.map(c => {
				if (c.toLowerCase().includes(v) && newCities.length < numOptionsToShow) {
					newCities.push({
						dataContext: t,
						text: c,
					});
				}
			});

			keywordOptionsMap.map(k => {
				if (k[0].toLowerCase().includes(v) && k[1].toLowerCase() === tzName && newTimezones.length < numOptionsToShow) {
					newTimezones.unshift({
						dataContext: t,
						text: k[0],
					});
				}
			});

			if (tzName.includes(v) && newTimezones.length < numOptionsToShow) {
				newTimezones.push({
					dataContext: t,
					text: t.name,
				});
			}
		});

		setCities(newCities);
		setTimezones(newTimezones);
	};

	const selectTimezone = (tz: ITimezoneOption) => () => {
		setValue(tz.text);
		onTimezoneSelected(tz.dataContext);
		setIsOpen(false);
	};

	return (
		<div className={`${css(styleSheet.container, styles)}`}>
			{loadingTimezoneData ? (
				<LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='tiny' />
			) : (
				<>
					<TextInput
						disabled={disabled}
						inputId='timezone-picker-input'
						onChange={onInputChange}
						onFocus={onInputFocus}
						onKeyDown={onInputKeyDown}
						placeholder={placeholder || 'Enter a city or timezone'}
						type='text'
						value={value}
						className={css(inputStyles)}
					/>
					<TinyPopover
						contentStyles={[styleSheet.resultsContainer]}
						dismissOnOutsideAction={true}
						isOpen={isOpen}
						onRequestClose={onRequestClose}
					>
						{renderTimezoneOptions()}
					</TinyPopover>
				</>
			)}
		</div>
	);
};
